<template>
	<div class="tally-sheet">
		<div class="title">
			<span class="icon">
				<i :class="menuIcon"></i>
			</span>
			<span class="txt">{{menuName}}</span>
		</div>
		<div class="form-list">
			<el-table
				ref="table"
				class="table"
				:data="formList"
				max-height="1000"
				:row-class-name="tableRowClassName"
				style="width: 100%;">
				<el-table-column label="表单模板" min-width="80%" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="20%" align="center">
					<template slot-scope="scope">
						<el-button @click="loadBtnClick(scope.row.tid, scope.row.name)" type="primary">
							加载
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>


<script>
	import {
		getFormList,
		loadFormTemplate
	} from 'network/tallySheet'
	
	export default {
		name: "Tally-Sheet",
		data() {
			return {
				tableHeight: 1000,
				formList: [],
				dialogVisible: false,
				limit: 1,  // 上传excell时，同时允许上传的最大数
				fileList: [],// excel文件列表,
				baseUrl: sessionStorage.getItem("baseUrl"),
			}
		},
		created() {
			this.getFormList1()
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		methods: {
			//请求表单模板数据
			getFormList1() {
				let obj = {
					eqId: sessionStorage.getItem("eqId")
				}
				getFormList(obj).then(res => {
					if(res.data.code !== 0)
					return this.$message.error("请求数据失败")
					this.formList = res.data.data;
					// console.log(res.data.data);
				})
			},
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 1) {
					return 'success-row';
				}
				return '';
			},
			//查看文件
			openFile(fileUrl) {
				// console.log(fileUrl)
				let goUrl = this.isMobile();
				if (goUrl === 1) {
					//移动端
					window.external.Openfile(fileUrl);
				} else {
					//PC
					window.open(fileUrl);
				}
			},
			//判断Windows 移动端
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},
			loadBtnClick(id, name) {
				// window.open("http://127.0.0.1:8848/ExcelDemo/index.html?vehicleId=" + id, "_self");
				let obj = {
					deptId: sessionStorage.getItem("stationId"),
					tid: id
				}
				// console.log(obj.deptId + "-------" + obj.tid);
				loadFormTemplate(obj).then(res => {
					//console.log(res);
					if(res.data.code !== 0)
					return this.$message.error("请求数据失败");
					// 请求回来的excel
					var fileUrl = this.baseUrl + res.data.data.filePath;
					// 工位ID
					var deptId = sessionStorage.getItem("stationId");
					// 模板ID
					var tId = id;
					// 用户ID
					var userId = sessionStorage.getItem("userId");
					var fileName = name;
					// this.$bus.$emit('showEditSheet', fileUrl, tId, fileName);
					// console.log(userId);
					var baseUrl = res.data.data.baseUrl;
					// console.log(baseUrl);
					var url = "http://127.0.0.1:8848/test/spread.html?fileUrl=" + fileUrl + "&baseUrl=" + baseUrl + "&deptId=" + deptId + "&tId=" + tId + "&fileName=" + fileName + "&userId=" + userId;
					// var url = baseUrl + "/sopweb/spread.html?fileUrl=" + fileUrl + "&baseUrl=" + baseUrl + "&deptId=" + deptId + "&tId=" + tId + "&fileName=" + fileName + "&userId=" + userId;
					window.open(url, "_self");
				})
			},
		}
	}
</script>
<style>
/* 表格隔行变色颜色 */
.el-table .success-row {
	background: rgba(244,246,247,1);
}
/*表格字体*/
.el-table {
	font-size: 1.375rem !important;
}
/*表头样式*/
.el-table th {
	background: rgba(245,247,247,1) !important;
	font-size: 1.375rem !important;
	font-family: PingFang SC !important;
	font-weight: 500 !important;
	color: rgba(58,132,171,1) !important;
}
.el-table td, .el-table th {
	height: 3.75rem !important;
	padding: 0 !important;
}
.el-table .cell {
	padding: 0.625rem 0 !important;
}
</style>
<style lang="scss" scoped>
.tally-sheet {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
	border-radius: 0.625rem;
	box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			text-align: center;
			line-height: 2.5rem;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.txt {
			height: 2.5rem;
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			line-height: 2.5rem;
			letter-spacing: 3px;
		}
	}
	.form-list {
		width: 100%;
		height: 100%;
	}
}
</style>
