import instance from './request'
//获取表单模板列表
export function getFormList(dataObj) {
  return instance({
    url: 'form/getFormTemplateList',
    method: 'POST',
    params: dataObj
  })
}
//加载表单模板
export function loadFormTemplate(dataObj) {
  return instance({
    url: 'form/getFormTemplate',
    method: 'POST',
    params: dataObj,
  })
}